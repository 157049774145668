#film-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
    align-content: center;
    overflow-x: hidden
}

#film-box{
    display: flex;
    width: 520px;
    flex-direction: column;
    align-items: center;
}

.video-box{
    display: flex;
    z-index: 3;
    justify-content: center;
    width: 635px;
    height: 360px;
    align-items: center;
}

#unmute-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    width: 518px;
    height: 360px;
    background-color: rgb(0, 0, 0);
    box-sizing: border-box;
}

#unmute-text{
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 600;
    font-size: 10px;
    color: #ffffff;
    line-height: 13px;
    animation: trans 3s;
}

@keyframes trans {
    0% {display: none; opacity: 0;}
    50% {display: block; opacity: 1;}
    100% { display: none; opacity: 0;}
}

#white-crop {
    position: absolute;
    z-index: 5;
    width: 630px;
    height: 444px;
    margin-top: -5px;
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    pointer-events: none;
}

#white-bar {
    background-color: #FCFCFC;
    width: 56px;
    height: 100%;
}

#film-top {
    display: flex;
    background-color: #FCFCFC;
    height: 30px;
    width: 100%;
    margin-bottom: -10px;
    z-index: 7;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 300;
    font-size: 10px;
    color: #000000;
    line-height: 13px;
    align-self: flex-start;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#film-title {
    display: flex;
    justify-content: center;
    width: 100%;
}

#film-count {
    display: flex;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 300;
    width: 100%;
    font-size: 10px;
    padding-right: 20px;
    margin-bottom: -13px;
    color: #000000;
    z-index: 8;
    line-height: 13px;
    justify-content: flex-end;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#mute-click-box {
    position: absolute;
    width: 520px;
    z-index: 1;
    height: 350px;
    margin-top: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

#button-row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    cursor: pointer;
}

#playPause {
    display: flex;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 300;
    font-size: 10px;
    color: #000000;
    line-height: 13px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@media (max-height: 600px) {
    #film-container {height: auto}
}

@media (max-width: 620px) {
    #white-crop { width: 100%;}
    #white-bar {width: 45px;}
    #film-box{width:100%;}
    .video-box {width: 110%;}
    #unmute-box {height: auto; padding: 29%; width: 100%;}
    #mute-click-box {height: auto; padding: 29%; width: 100%;}
}

@media (max-width: 600px) {
    #white-bar {width: 34px;}
    #unmute-box {padding: 30%;}
    #mute-click-box {padding: 30%}
}

@media (max-width: 575px) {
    #white-bar {width: 25px;}
}

@media (max-width: 565px) {
    #white-bar { width: 20px;}
}

@media (max-width: 536px) {
    #film-box{height: 400px; }
    #white-crop { display: none}
    #unmute-box {padding: 33%;}
    #mute-click-box {padding: 33%}

}

@media (max-width: 450px) {
    #film-box{height: 350px; }
}


button:focus {
    outline: none;
    box-shadow: none;
}

input {
    width: 600px;
}

textarea {
    height: 10em;
    width: 600px;
}

div[role='alert'] {
    color: red;
    margin-top: 1em;
}

*:focus {
    outline: none;
}

.contact-contents {
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.name-info {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
}

#name-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#info1 {
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 600;
    font-size: 9px;
    color: rgb(0, 0, 0);
    line-height: 17px;
}

#info2 {
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    color: rgb(125, 123, 121);
    line-height: 17px;
}

.vertical-line-box {
    order: 2;
    width: 110px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

#vertical-line {
    display: flex;
    box-shadow: -.4px 0 0 #000000;
    width: 1px;
    height: 500px;
}

#form-info {
    order: 3;
    display: flex;
    flex-direction: column;
    width: 40%;
}

.text-info-box {
    background-color: #FCFCFC;

    width: 100%;
    display: flex;
    flex-direction: column;
}

.input-title-box{
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

#input-title {
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    color: rgb(125, 123, 121);
}

#required-error{
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    padding-left: 3px;
    color: #CD5454;
}

.input-box1{
    background-color: #FCFCFC;
    width: 246px;
    height: 28px;
    border: none;
    box-shadow: 0 0 0 0.4px black;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    color: rgb(0, 0, 0);
    padding: 0 5px 0 5px;
    margin-bottom: 8px;
    border-radius: 0;
    /* Removes shadow from phone devices */
    background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
}

.input-box2{
    background-color: #FCFCFC;
    width: 246px;
    border: none;
    box-shadow: 0 0 0 0.4px black;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    color: rgb(0, 0, 0);
    padding: 8px 5px 0 5px;
    height: 130px;
    resize: none;
    border-radius: 0;
    /* Removes shadow from phone devices */
    background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
}

.message-chars-left{
    width: 259px;
    display: flex;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    justify-content: flex-end;
    margin: 0 0 25px 0;
}

#submit-button{
    cursor: pointer;
    border: none;
    width: 119px;
    height: 25px;
    background-color: black;
    color: white;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 600;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
}

#submit-button:active{
    background-color: #404040;
}

.status-message {
    opacity: 0;
}

.status-box{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    z-index: 4;
    top: 0;
}

.status-message-box {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border: black .4px solid;
    height: 223px;
    width: 516px;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    align-self: center;
}

#status-message1 {
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 600;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    line-height: 20px;
}

#status-message2 {
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 400;
    font-size: 9px;
    display: flex;
    align-items: center;
    color: #7D7B79;
    justify-content: center;
}

#done-button {
    cursor: pointer;
    border: none;
    width: 119px;
    height: 25px;
    background-color: black;
    color: white;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: 600;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

#done-button:active{
    background-color: #404040;
}

@media (max-width:900px) {
    .contact-contents {flex-direction: column;}
    #form-info {order: 1; width: 100%; align-items: center;}
    .text-info-box{ width: auto;}
    .vertical-line-box {width: 80%; margin: 60px 0 60px 0;}
    #vertical-line {box-shadow: 0 -.4px 0 #000000;width: 500px;height: 1px; }
    .name-info {order: 3; width: 100%;align-items: center; margin-bottom: 50px;}
    .status-message-box {width: 80%; height: 200px; transform: translateY(100%);}
} 
#collection-box {
    background-color: #FCFCFC;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: auto;
}

#image{
    padding: 15px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#display-image{
    display: none;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 3;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    user-select: none; 
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
}

#image-box{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.smallSizes{
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

#large-size-box {
    display: block;
    width: 100%;
    height: 100%;
    margin-left: 50px;
    margin-right: 50px;
}

.largeSizes {
    display: contents;
}

/* small screen without carousel */
@media (max-height: 400px) and (max-width: 850px){
    .smallSizes{display: flex;}
    #image{cursor:initial; padding: 0; padding-bottom: 10px; padding-top: 10px;}
    .largeSizes{display: none;}
  } 
@media (max-width:750px) {
    .smallSizes{display: flex;}
    #image{cursor:initial; padding: 0; padding-bottom: 10px; padding-top: 10px;}
    .largeSizes{display: none;}
}
  

  
#center{
  height: auto;
  position: relative;
}

.embla {
  position: relative;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  
}
.shadow1 {
  position: absolute;
  top: 0;
  background: linear-gradient(to left,#00000000 0%, #000000 130%);  
  height: 530px;
  width: 250px;
  left: 0;
}
.shadow2 {
  position: absolute;
  top: 0;
  background: linear-gradient(to right,#00000000 0%, #000000 130%);  
  height: 530px;
  width: 250px;
  right: 0;
}

.white-box{
  position: absolute;
  width: 100%;
  height: 530px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.white-cut1 {
  background-color: #FCFCFC;
  width: 100%;
  height: 6px;
  position: absolute;
  top: -3px;
}
.white-cut2 {
  background-color: #FCFCFC;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: -3px;
}
.embla__slide .embla__slide__inner .embla__slide__img {
  filter: blur(5px);
}
.embla__slide:nth-child(1) .embla__slide__inner .embla__slide__img {
  filter: blur(0px);
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.embla__slide {
  position: relative;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  z-index: -1;
  left: 50%;
  min-width: 100%;
  max-width: none;
  height: 530px;
  transform: translate(-50%, -50%);
}

.embla__slide:nth-child(1) {
  min-width: 795px;
}

.embla__slide:nth-child(2) {
  min-width: 795px;
}

.embla__slide:nth-child(3) {
  min-width: 795px;
}

.embla__slide:nth-child(4) {
  min-width: 795px;
}

.embla__slide:nth-child(5) {
  min-width: 795px;
}

.embla__slide:nth-child(6) {
  min-width: 795px;
}

.embla__slide:nth-child(7) {
  min-width: 795px;
}

.embla__slide:nth-child(8) {
  min-width: 350px;
}

.embla__slide:nth-child(9) {
  min-width: 795px;
}

.embla__slide:nth-child(10) {
  min-width: 795px;
}

.embla__slide:nth-child(11) {
  min-width: 795px;
}

.embla__slide:nth-child(12) {
  min-width: 795px;
}

.embla__slide:nth-child(13) {
  min-width: 795px;
}

.embla__slide:nth-child(14) {
  min-width: 350px;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 530px;
  margin: 0 9.5px 0 9.5px;
}

.buttonRow{
  background-color: #FCFCFC;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90px;
  user-select: none; 
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none; 
}
  
#textButton{
  display: flex;
  border: none;
  font-family: 'Hind Siliguri', sans-serif;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0);
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  cursor: pointer;
  padding: 0;
  left: 0;
  z-index: 4;
  user-select: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
}

#prevSlideButton{
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 530px;
}

#nextSlideButton{
  cursor: pointer;
  display: flex;
  height: 530px;
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  height: 530px;
}

button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
  -webkit-appearance: none;
}

.description-box {
  width: 795px;
  display: flex;
}

.photo-column {
  display: none;
  flex-direction: column;
}

#photo{
  width: 100%;
}

#s0 {
  display: flex;
}

.captionSlide {
  position: absolute;
  display: none;
  padding-top: 10px;
  font-family: 'Hind Siliguri', sans-serif;
  font-weight: 400;
  font-size: 9px;
  color: #000000;
  line-height: 13px;
  margin: 0 9.5px 0 9.5px;
  animation: fadeIn 1.5s linear;
}

#picture {
  background-color: #FCFCFC;
  font-family: 'Hind Siliguri', sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #000000;
  line-height: 13px;
  margin: 10px;
  animation: fadeIn 1.5s linear;
}

@keyframes fadeIn{
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeOut{
  from {opacity: 1;}
  to {opacity: 0;}
}

@media (max-width:1300px) {
  #center{height: 80%;}
  .shadow1{width: 100px;}
  .shadow2{width: 100px;}
} 

@media (max-width:1000px) {
  #center{height: 80%;}
  .shadow1{width: 50px;}
  .shadow2{width: 50px;}
} 
@media (max-width: 900px) {
  #center{height: 80%;}
  .shadow1{display: none;}
  .shadow2{display: none}
} 

@media (min-height:820px) {
  .embla{top: 45%; transform: translateY(-45%);}
  #center{height: 90%;}
} 

/* small screen without carousel */
@media (max-height: 400px) and (max-width: 850px){
  .description-box {display: none}
  .embla {display: none;}
  .photo-column{display: flex;}
  #picture-descrip{padding-bottom: 10px; padding-left: 10px;}
} 
@media (max-width: 795px){
  .description-box {display: none}
  .embla {display: none;}
  .photo-column{display: flex;}
  #picture-descrip{padding-bottom: 10px; padding-left: 10px;}
} 


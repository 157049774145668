#root {
  overflow: scroll;
  background-color: #FCFCFC;
}

html {
  overflow: scroll;
}

a:link, a:visited,  a:hover, a:active {
  text-decoration: none;
  color: rgb(161, 160, 159);
}

button:focus {
  outline: none;
  box-shadow: none;
}

.App-header{
  display: flex;
  flex-direction: column;
  background-color: #FCFCFC;
  height: 150px;
  position: relative;
  z-index: 3;
  width: 100%;
}

.button-nav{
  display: flex;
  background-color: #FCFCFC;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
}

.btn-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 40px;
  font-family: 'Hind Siliguri', sans-serif;
  color: rgb(67, 66, 64, .5);
  background-color: rgba(255, 255, 255, 0);
  font-size: 11px;
  font-weight: 300;
  line-height: 18px;
  cursor: pointer;
  padding: 0;
  margin-top: 10px;
  text-align: center;
  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

.btn-nav:hover{
  color: #000000;
  transition: color ease-in-out 0.2s;
}

#name-container{
  display: flex;
  position: absolute;
  top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#name{
  display: flex;
  font-family: 'Hind Siliguri', sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  color: #434240;
  letter-spacing: 0.8px;
}

#email{
  display: flex;
  font-family: 'Hind Siliguri', sans-serif;
  font-weight: 400;
  font-size: 9px;
  color: #7D7B79;
  line-height: 13px;
}

#portfolio-tab {
  justify-content: flex-end;
  padding-right: 20px;
  padding-left: 40px;
}

#contact-tab {
  justify-content: flex-end;
  padding-right: 7px;
  padding-left: 20px;
}

#film-tab {
  justify-content: flex-end;
  padding-left: 7px;
  padding-right: 20px;
}

#menu{
  user-select: none;
  display: grid;
  width: 130px;
  background-color: #FCFCFC;
  z-index: 1;
  margin-top: 10px;
}

#collection-tab{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 24px;
  font-family: 'Hind Siliguri', sans-serif;
  color: rgb(67, 66, 64, .5);
  background-color: rgba(255, 255, 255, 0);
  font-size: 11px;
  font-weight: 300;
  line-height: 18px;
  cursor: pointer;
  padding-top: 7px;
}

.line-box{
    height: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#line {
  box-shadow: 0 -1px 1px -1px black;
  height: 9px;
  width: 85px;
}

.menu-options{
  width: 100%;
  height: 30px;
  font-family: 'Hind Siliguri', sans-serif;
  color: rgb(67, 66, 64, .5);
  background-color: rgba(255, 255, 255, 0);
  font-size: 11px;
  font-weight: 300;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.menu-options:hover {
  color: rgb(0, 0, 0);
  transition: color ease-in-out 0.2s;
}

#drop-down {
  display: flex;
  flex-direction: column;
}


/* .rights-box{
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  color: rgb(0, 0, 0);
  font-family: 'Hind Siliguri', sans-serif;
  font-weight: 300;
  font-size: 13px;
} */


@media (max-width:1000px) {
  .button-nav{justify-content: center; padding: 0}
} 

@media (max-width: 795px) {
.App-header{height: 160px}
}

@media (max-width: 435px) {
  #portfolio-tab{padding: 0; justify-content: center; width: 90px;}
  #contact-tab{padding: 0; justify-content: center;width: 90px;}
  #menu{width: 100px;}
  .menu-options{height: 45px;}
  #line{width: 60px;}
  #drop-down {padding-left: 10px; padding-right: 10px;}
  #film-tab{padding: 0; justify-content: center;width: 90px;}
} 


